@tailwind base;
@tailwind components;
@tailwind utilities;

/* -------------------> VARIABLEs <------------------- */

:root {
  /* Colors */
  --mainTextColor: black;
  --backgroundColor: #ededed;
  --violetColor: #b777f7;
  --darkGreyColor: #a1a1a1;
  --lightBlueColor: #72dafb;
  --greenColor: #8cd782;
  --yellowColor: #edab2c;
  --redColor: #ed672c;
  --blueColor: #5b99f4;
  --whiteColor: #ffffff;
  /* Font size */
  --mainTextSize: 24px;
  --h2TextSize: 39px;
  --pTextSize: 24px;
  --captionFontSize: 18px;
  --menuFontSize: 24px;
  /* Font size mobile */
  --mainTextSizeMobile: 16px;
  --h2TextSizeMobile: 25px;
  --pTextSizeMobile: 16px;
  --captionFontSizeMobile: 16px;
  --menuFontSizeMobile: 42px;
  /* Inner Glow sizes */
  --blur: 60px;
  --spread: 2px;
  --blurHover: 80px;
  --spreadHover: 40px;

  --gapGrid: 8px;
  /* Font size desktop */
  --fontHomeSizeTitle: 45px; /* 45 px */
  --fontHomeSizeDescription: 28px; /* 29 px */
  /* Font size mobile */
  --fontHomeSizeTitleMobile: 2.5vh;
  --fontHomeSizeDescriptionMobile: 2.5vh;

  position: relative;
}

/* -------------------> FONT <------------------- */
@font-face {
  font-family: EditorialNew;
  src: url("assets/font/EditorialNewRegular.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("assets/font/SuisseIntlRegular.otf") format("opentype");
  font-display: swap;
}

/* Font not used 
@font-face {
  font-family: EditorialNew-Italic;
  src: url("../font/EditorialNewItalic.otf") format("opentype");
  font-display: swap;
}

/* -------------------> STYLE DESKTOP <------------------- */

html {
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  margin: 0;
  font-size: 15px;
  font-family: "SuisseIntl", "Helvetica", sans-serif;
  color: var(--mainTextColor);
  background-color: var(--backgroundColor);
  scrollbar-width: auto;
  scrollbar-color: #7c7c7c #ffffff00;
  overflow-x: hidden;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #7c7c7c;
  border-radius: 5px;
}

::selection {
  color: var(--backgroundColor);
  background: var(--mainTextColor);
}

/* START -- Font size and family */
h1 {
  font-size: var(--mainTextSize);
  font-family: "SuisseIntl", "Helvetica", sans-serif;
  font-weight: normal;
}

h2 {
  font-size: var(--h2TextSize);
  font-weight: normal;
  font-family: "EditorialNew", "Garamond", serif;
  line-height: 1.28;
}

h3 {
  font-size: var(--h2TextSize);
  font-weight: normal;
  font-family: "EditorialNew", "Garamond", serif;
}

p {
  font-size: var(--pTextSize);
  font-family: "SuisseIntl", "Helvetica", sans-serif;
  line-height: normal;
}

a div.box {
  color: var(--mainTextColor);
}

a {
  text-decoration: none;
  display: flex;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.captionFont {
  font-size: var(--captionFontSize);
}

.glassBox {
  box-shadow: 0px 0px 35px 20px #ffffff inset;
  background-color: #d9d9d97b;
}

/* -------------------> STYLE MOBILE <------------------- */

@media screen and (max-width: 820px) {
  body {
    margin: 5px;
    font-size: 12px;
  }

  /* START -- Font size and family */
  h1 {
    font-size: var(--mainTextSizeMobile);
  }

  h2 {
    font-size: var(--h2TextSizeMobile);
  }

  h3 {
    font-size: var(--h2TextSizeMobile);
  }

  p {
    font-size: var(--pTextSizeMobile);
  }

  .captionFont {
    font-size: var(--captionFontSizeMobile);
  }

  .menuFont {
    font-size: var(--menuFontSizeMobile);
  }
  /* END -- Font size and family */
}

/* -------------------> Homepage Style <------------------- */

/* -------------------> STYLE DESKTOP <------------------- */

.wrapper {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: var(--gapGrid);
}

.wrapper a{
  min-height: 20vh;
}

.box {
  padding: 5px 5px 5px 5px;
  padding-left: 11px;
  position: relative;
  background-color: var(--backgroundColor);
}

#box-1 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 4;
}
#box-2 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
}
#box-3 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}
#box-4 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}
#box-5 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
}
#box-6 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;
}
#box-7 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
}

.titleHome {
  margin: 0 0 var(--fontHomeSizeTitle) 0;
  font-size: var(--fontHomeSizeTitle);
}

.subtitleHome {
  margin: 0;
  font-family: "SuisseIntl", "Helvetica", sans-serif;
  font-weight: normal;
  /* font-size: var(--fontHomeSizeTitle); */
  line-height: calc(var(--fontHomeSizeTitle) * 1.1);
}

.pagesInfo {
  margin: 10px 0px;
  font-family: "SuisseIntl", "Helvetica", sans-serif;
  font-weight: normal;
  font-size: var(--fontHomeSizeTitle);
  line-height: var(--fontHomeSizeDescription);
}

.pagesInfo .pagesTitle {
  font-size: var(--fontHomeSizeTitle);
  line-height: var(--fontHomeSizeTitle);
}

.pagesInfo .pagesDescription {
  font-size: var(--fontHomeSizeDescription);
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.box:hover span:first-of-type + span {
  visibility: visible;
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.box:hover span:first-of-type {
  display: none;
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.innerGlowMain {
  border-radius: 16px;
}

.innerGlowAnimated {
  transition: all 300ms ease-in-out;
}

.innerGlowAnimated:hover {
  transition: all 300ms ease-in-out;
}

.innerGlowViolet {
  -moz-box-shadow: inset 0 0 var(--blur) var(--spread) var(--violetColor);
  -webkit-box-shadow: inset 0 0 var(--blur) var(--spread) var(--violetColor);
  box-shadow: inset 0 0 var(--blur) var(--spread) var(--violetColor);
}

.innerGlowViolet:hover {
  -moz-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--violetColor);
  -webkit-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--violetColor);
  box-shadow: inset 0 0 var(--blurHover) var(--spreadHover) var(--violetColor);
}

.innerGlowDarkGrey {
  -moz-box-shadow: inset 0 0 var(--blur) var(--spread) var(--darkGreyColor);
  -webkit-box-shadow: inset 0 0 var(--blur) var(--spread) var(--darkGreyColor);
  box-shadow: inset 0 0 var(--blur) var(--spread) var(--darkGreyColor);
}

.innerGlowDarkGrey:hover {
  -moz-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--darkGreyColor);
  -webkit-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--darkGreyColor);
  box-shadow: inset 0 0 var(--blurHover) var(--spreadHover) var(--darkGreyColor);
}

.innerGlowLightBlue {
  -moz-box-shadow: inset 0 0 var(--blur) var(--spread) var(--lightBlueColor);
  -webkit-box-shadow: inset 0 0 var(--blur) var(--spread) var(--lightBlueColor);
  box-shadow: inset 0 0 var(--blur) var(--spread) var(--lightBlueColor);
}

.innerGlowLightBlue:hover {
  -moz-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--lightBlueColor);
  -webkit-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--lightBlueColor);
  box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--lightBlueColor);
}

.innerGlowGreen {
  -moz-box-shadow: inset 0 0 var(--blur) var(--spread) var(--greenColor);
  -webkit-box-shadow: inset 0 0 var(--blur) var(--spread) var(--greenColor);
  box-shadow: inset 0 0 var(--blur) var(--spread) var(--greenColor);
}

.innerGlowGreen:hover {
  -moz-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--greenColor);
  -webkit-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--greenColor);
  box-shadow: inset 0 0 var(--blurHover) var(--spreadHover) var(--greenColor);
}

.innerGlowYellow {
  -moz-box-shadow: inset 0 0 var(--blur) var(--spread) var(--yellowColor);
  -webkit-box-shadow: inset 0 0 var(--blur) var(--spread) var(--yellowColor);
  box-shadow: inset 0 0 var(--blur) var(--spread) var(--yellowColor);
}

.innerGlowYellow:hover {
  -moz-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--yellowColor);
  -webkit-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--yellowColor);
  box-shadow: inset 0 0 var(--blurHover) var(--spreadHover) var(--yellowColor);
}

.innerGlowRed {
  -moz-box-shadow: inset 0 0 var(--blur) var(--spread) var(--redColor);
  -webkit-box-shadow: inset 0 0 var(--blur) var(--spread) var(--redColor);
  box-shadow: inset 0 0 var(--blur) var(--spread) var(--redColor);
}

.innerGlowRed:hover {
  -moz-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover) var(--redColor);
  -webkit-box-shadow: inset 0 0 var(--blurHover) var(--spreadHover)
    var(--redColor);
  box-shadow: inset 0 0 var(--blurHover) var(--spreadHover) var(--redColor);
}

.innerGlowWhite {
  -moz-box-shadow: inset 0 0 var(--blur) var(--spread) var(--whiteColor);
  -webkit-box-shadow: inset 0 0 var(--blur) var(--spread) var(--whiteColor);
  box-shadow: inset 0 0 var(--blur) var(--spread) var(--whiteColor);
}

/* END -- innerGlow Classes */

/* -------------------> STYLE MOBILE <------------------- */

@media screen and (max-width: 820px) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, calc(calc(100vh / 7) - var(--gapGrid))) calc(
        calc(100vh / 8) - (var(--gapGrid) * 2)
      );
  }

  .wrapper a{
    min-height: 13vh;
  }

  .box {
    width: 100%;
    padding: 5px 20px 5px 10px;
    background-color: var(--backgroundColor);
  }

  #box-1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  #box-2 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
  }
  #box-3 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 4;
    grid-row-end: 4;
  }
  #box-4 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 5;
    grid-row-end: 5;
  }
  #box-5 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 6;
    grid-row-end: 6;
  }
  #box-6 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 7;
    grid-row-end: 7;
  }
  #box-7 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 8;
    grid-row-end: 8;
  }

  .titleHome {
    margin: 0 0 var(--fontHomeSizeTitleMobile) 0;
    font-size: var(--fontHomeSizeTitleMobile);
  }

  .subtitleHome {
    font-size: var(--fontHomeSizeTitleMobile);
    line-height: calc(var(--fontHomeSizeTitleMobile) * 1.1);
  }

  .pagesInfo {
    font-size: var(--fontHomeSizeTitleMobile);
    line-height: var(--fontHomeSizeDescriptionMobile);
  }

  .pagesInfo .pagesTitle {
    font-size: var(--fontHomeSizeTitleMobile);
    line-height: var(--fontHomeSizeTitleMobile);
  }

  .pagesInfo .pagesDescription {
    font-size: var(--fontHomeSizeDescriptionMobile);
  }

  .box:hover span:first-of-type + span {
    visibility: hidden;
    opacity: 0;
  }

  .box:hover span:first-of-type {
    display: inline-block;
    opacity: 1;
  }

  .captionFont {
    font-size: var(--captionFontSizeMobile);
  }

  .menuFont {
    font-size: var(--menuFontSizeMobile);
  }
  /* END -- Font size and family */
}
